// React
import React from 'react';

// Gatsby
import Img from 'gatsby-image';

// Styles
import styles from './hero.module.css';

export default ({ data }) => (
  <div className={styles.hero}>
    <div style={{ padding: '20px' }}>
      <Img className={styles.heroImage} alt={data.name} fluid={data.heroImage.fluid} />
    </div>
  </div>
);
