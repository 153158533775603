// React
import React from 'react';
import Helmet from 'react-helmet';

// Gatsby
import { graphql } from 'gatsby';

// Third-party
import get from 'lodash/get';

// Local components
import Hero from '../components/indexHero';
import Layout from '../components/layout';
import ArticlePreview from '../components/article-preview';

class RootIndex extends React.Component {
  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title');
    const posts = get(this, 'props.data.allContentfulBlogPost.edges');
    const [author] = get(this, 'props.data.allContentfulPerson.edges');

    return (
      <Layout location={this.props.location}>
        <div style={{ background: '#fff', color: '#373f49' }}>
          <Helmet title={siteTitle} />
          <Hero data={author.node} />
          <div className="wrapper">
            <div style={{ marginBottom: '12px' }}>
              The purpose of this site is to track the progress of my 2019 Dodge Challenger R/T Scat
              Pack 1320. I will be documenting any modifications and detailing trips to the drag
              strip.
            </div>
            <div>
              <ul>
                {/* <li>
                  Blog
                </li> */}
                <li>
                  <a href="/events/">Events/Outings</a> - a list of the trips I have taken to the
                  drag strip and the runs made there
                </li>
                <li>
                  <a href="/runs/">All Runs</a> - a list of all drag strip passes I have made to
                  date
                </li>
              </ul>
            </div>
            {/* <h2 className="section-headline">Recent posts</h2>
            <ul className="article-list">
              {posts.map(({ node }) => {
                return (
                  <li key={node.slug}>
                    <ArticlePreview article={node} />
                  </li>
                );
              })}
            </ul> */}
          </div>
        </div>
      </Layout>
    );
  }
}

export default RootIndex;

export const pageQuery = graphql`
  query HomeQuery {
    site {
      siteMetadata {
        title
      }
    }
    allContentfulBlogPost(sort: { fields: [publishDate], order: DESC }, limit: 3) {
      edges {
        node {
          title
          slug
          publishDate(formatString: "MMMM Do, YYYY")
          tags
          heroImage {
            fluid(maxWidth: 350, maxHeight: 233, resizingBehavior: SCALE) {
              ...GatsbyContentfulFluid_tracedSVG
            }
          }
          description {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
    allContentfulPerson(filter: { contentful_id: { eq: "15jwOBqpxqSAOy2eOO4S0m" } }) {
      edges {
        node {
          name
          shortBio {
            shortBio
          }
          heroImage: image {
            fluid(maxWidth: 1180, maxHeight: 480, resizingBehavior: PAD, background: "rgb:FFFFFF") {
              ...GatsbyContentfulFluid_tracedSVG
            }
          }
        }
      }
    }
  }
`;
